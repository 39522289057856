.homePage {
  margin: 0 auto;
  max-width: 600px;
}

.homePageTitle {
  color: #fff;
  font-family: "Pacifico", cursive;
  font-size: 56px;
  font-weight: normal;
  text-align: center;
  margin: 16px auto;
}
