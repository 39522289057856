.container {
  text-align: center;
}

.introButton {
  background: rgb(114, 52, 126);
  border: 4px;
  border-radius: 20px;
  color: antiquewhite;
  cursor: pointer;
  font-size: 18px;
  line-height: 40px;
  margin: 16px auto;
  width: 200px;
}

.introButton:hover {
  background-color: rgba(127, 178, 255, 0.4);
}

.top {
  text-align: center;
  font-size: 21px;
  font-family: Pacifico;
  color: black;
  margin: auto;
  max-width: 90%;
}

.foot {
  color: rgb(50, 50, 50);
  text-align: center;
  font-size: 18px;
  font-family: Calibri, bold;
}

.covid {
  background-image: url("/covid/angry_purple.png");
  background-size: cover;
  display: block;
  height: 200px;
  margin: 16px auto;
  width: 200px;
}
