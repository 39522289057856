@import url(https://fonts.googleapis.com/css?family=Pacifico&display=swap);
.question_question__2xGxY {
  text-align: center;
}

.question_questionNumber__1BXe1 {
  font-size: 14px;
  font-family: "Pacifico", cursive;
  margin: 0;
  text-align: center;
}

.question_questionTitle__2oUE4 {
  color: #333;
  font-family: "Courgette", cursive;
  font-size: 40px;
  margin: 0 0 32px 0;
  text-align: center;
}

.question_options__22m2m {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}

.question_optionsReadOnly__2kI-S {
  pointer-events: none;
}

.question_options__22m2m > li {
  margin: 16px 0;
}

.question_option__1WZ3C {
  background: rgba(255, 255, 255, 0.6);
  border: none;
  cursor: pointer;
  font-size: 18px;
  min-height: 32px;
  max-width: 400px;
  padding: 8px 16px;
  width: 90%;
}

.question_option__1WZ3C:hover {
  background: rgba(255, 255, 255, 0.7);
}

.question_selectedOption__3rWBw,
.question_selectedOption__3rWBw:hover {
  background: rgba(52, 93, 126, 0.6);
  color: #fff;
}

.question_selectedOptionCorrect__1LnZ8,
.question_optionCorrect__qsriY,
.question_selectedOptionIncorrect__3xmYJ {
  position: relative;
}

.question_optionCorrect__qsriY::after,
.question_selectedOptionCorrect__1LnZ8::after,
.question_selectedOptionIncorrect__3xmYJ::after {
  background: green;
  -webkit-mask-image: url("/icons/check.svg");
          mask-image: url("/icons/check.svg");
  -webkit-mask-size: cover;
          mask-size: cover;
  background-size: cover;
  border-radius: 50%;
  content: "";
  height: 32px;
  position: absolute;
  right: -16px;
  top: calc(50% - 16px);
  width: 32px;
}

.question_optionCorrect__qsriY::after {
  opacity: 0.6;
}

.question_selectedOptionIncorrect__3xmYJ::after {
  background: red;
  -webkit-mask-image: url("/icons/x-mark.svg");
          mask-image: url("/icons/x-mark.svg");
}

.question_nextButton__WdJfc {
  background: rgba(52, 93, 126, 1);
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  line-height: 32px;
  margin: 24px auto 0;
  width: 120px;
}

.question_nextButtonDisabled__3abdd {
  pointer-events: none;
  opacity: 0.6;
}

.question_sourceLink__2TUCF {
  font-size: 18px;
  color: #eee;
  text-decoration: none;
  display: block;
  padding: 16px 0;
}

.question_sourceLink__2TUCF:hover {
  text-decoration: underline;
}

.home_homePage__Kblpr {
  margin: 0 auto;
  max-width: 600px;
}

.home_homePageTitle__2X1dg {
  color: #fff;
  font-family: "Pacifico", cursive;
  font-size: 56px;
  font-weight: normal;
  text-align: center;
  margin: 16px auto;
}

.intro_container__Rl7du {
  text-align: center;
}

.intro_introButton__fd7Uq {
  background: rgb(114, 52, 126);
  border: 4px;
  border-radius: 20px;
  color: antiquewhite;
  cursor: pointer;
  font-size: 18px;
  line-height: 40px;
  margin: 16px auto;
  width: 200px;
}

.intro_introButton__fd7Uq:hover {
  background-color: rgba(127, 178, 255, 0.4);
}

.intro_top__13NIz {
  text-align: center;
  font-size: 21px;
  font-family: Pacifico;
  color: black;
  margin: auto;
  max-width: 90%;
}

.intro_foot__37Cgl {
  color: rgb(50, 50, 50);
  text-align: center;
  font-size: 18px;
  font-family: Calibri, bold;
}

.intro_covid__2rMcp {
  background-image: url("/covid/angry_purple.png");
  background-size: cover;
  display: block;
  height: 200px;
  margin: 16px auto;
  width: 200px;
}

.finish_finishContainer__3K5qN {
  text-align: center;
}

.finish_top__2Wa-w {
  text-align: center;
  font-size: 21px;
  font-family: Pacifico;
  color: black;
  margin: auto;
  max-width: 90%;
}

.finish_circleOuter__247sR {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  color: rgb(114, 52, 126);
  height: 200px;
  margin: 24px auto;
  padding: 8px;
  width: 200px;
}

.finish_circleInner__1BLYs {
  background-color: #fff;
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 200px;
  overflow: hidden;
  width: 200px;
}

.finish_failedTest__24-Oj {
  color: red;
}

.finish_approvedTest__1ITaa {
  color: yellow;
}

.finish_excellentTest__2sOS- {
  color: green;
}

.finish_circleInner__1BLYs p {
  text-align: center;
  font-family: "Pacifico", cursive;
  font-size: 18px;
  margin: 0;
}

.finish_circleInner__1BLYs p:not(.finish_score__1A17i) {
  color: rgb(114, 52, 126);
}

.finish_circleInner__1BLYs p.finish_score__1A17i {
  font-size: 56px;
  line-height: 48px;
}

.finish_restartButton__2M4_j {
  background: rgb(114, 52, 126);
  border: 4px;
  border-radius: 20px;
  color: antiquewhite;
  cursor: pointer;
  font-size: 18px;
  line-height: 40px;
  margin: 0 auto;
  width: 200px;
}

.finish_restartButton__2M4_j:hover {
  background-color: rgba(127, 178, 255, 0.4);
}

.finish_foot__35V4I {
  color: rgb(50, 50, 50);
  text-align: center;
  font-size: 18px;
  font-family: Calibri, bold;
  padding-top: 8px;
}

.finish_iconFacebook__2lsiC,
.finish_iconTwitter__C0XM9,
.finish_iconWhatsapp__1NgC2 {
  display: inline-block;
  background-color: #1877f2;
  border-radius: 8px;
  cursor: pointer;
  height: 32px;
  margin: 0 8px;
  opacity: 0.95;
  padding: 8px;
  position: relative;
  width: 32px;
}

.finish_iconFacebook__2lsiC:hover,
.finish_iconTwitter__C0XM9:hover,
.finish_iconWhatsapp__1NgC2:hover {
  opacity: 1;
}

.finish_iconFacebook__2lsiC::after,
.finish_iconTwitter__C0XM9::after,
.finish_iconWhatsapp__1NgC2::after {
  content: "";
  background: #fff;
  -webkit-mask-image: url("/icons/facebook.svg");
          mask-image: url("/icons/facebook.svg");
  display: block;
  height: 32px;
  width: 32px;
}

.finish_iconTwitter__C0XM9 {
  background-color: #1da1f2;
}

.finish_iconTwitter__C0XM9::after {
  -webkit-mask-image: url("/icons/twitter.svg");
          mask-image: url("/icons/twitter.svg");
}

.finish_iconWhatsapp__1NgC2 {
  background-color: #25d366;
}
.finish_iconWhatsapp__1NgC2::after {
  -webkit-mask-image: url("/icons/whatsapp.svg");
          mask-image: url("/icons/whatsapp.svg");
}

.styles_overlay__1NhjB {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.styles_footer__1Ybc4 {
  margin-top: 16px;
  text-align: center;
  width: 100%;
}

.styles_developedBy__38oii,
.styles_developedBy__38oii a {
  font-size: 12px;
  color: #333;
}

.styles_developedBy__38oii a {
  text-decoration: underline;
}

.styles_agreement__3hl6V {
  font-size: 11px;
  color: #333;
  margin: 0 auto 8px;
  width: 90%;
  max-width: 600px;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  background-image: linear-gradient(to bottom right, #345d7e, #cb5078, #f8bf95);
  background-size: cover;
  margin: 0;
  min-height: 100vh;
}

*:focus {
  outline: none;
}

