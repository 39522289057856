@import url("https://fonts.googleapis.com/css?family=Pacifico&display=swap");

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  background-image: linear-gradient(to bottom right, #345d7e, #cb5078, #f8bf95);
  background-size: cover;
  margin: 0;
  min-height: 100vh;
}

*:focus {
  outline: none;
}
