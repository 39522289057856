.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.footer {
  margin-top: 16px;
  text-align: center;
  width: 100%;
}

.developedBy,
.developedBy a {
  font-size: 12px;
  color: #333;
}

.developedBy a {
  text-decoration: underline;
}

.agreement {
  font-size: 11px;
  color: #333;
  margin: 0 auto 8px;
  width: 90%;
  max-width: 600px;
}
